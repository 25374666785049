
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class CommonProvide extends Vue {
  private firstTextEntered = 'くありすの情報発信局へ、ようこそ！\nイラストレーター兼Webエンジニアのくありすと申します。';
  private isActiveFlag = false;
  private displayMessageData = '';

  private onClick (): void {
    this.isActiveFlag = true
  }

  private displayMessage (): void {
    this.$emit('isMessage', this.displayMessageData)
  }

  private close (): void {
    this.isActiveFlag = false
  }
}
