import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"second-section"},[_c('div',{staticClass:"d-flex contents-wrap"},[_c(VCard,{staticClass:"yellow lighten-5",attrs:{"width":'100%'}},[_c(VTabs,{attrs:{"color":"deep-purple accent-4","background-color":"yellow lighten-5","right":""}},[_c(VTab,[_vm._v("CD(イラスト)")]),_vm._l((1),function(item){return _c(VTabItem,{key:item.id,staticClass:"yellow lighten-5"},[_c(VResponsive,{staticClass:"overflow-y-auto",attrs:{"height":"80vh"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.responseObject),function(items){return _c(VCol,{key:items.id,attrs:{"order":_vm.orderByDesc(items.id,_vm.responseObject.length),"cols":"12","md":"2"}},[_c(VImg,{attrs:{"src":items.url,"lazy-src":'',"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"green lighten-1"}})],1)]},proxy:true}],null,true)}),_c(VCard,{staticClass:"white",attrs:{"height":'40px'}},[_vm._v(" "+_vm._s(items.title)+" ")])],1)}),1)],1)],1)],1)})],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }