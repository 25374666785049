
import { Component, Vue, Watch } from 'vue-property-decorator'
import CommonView from '../components/templates/CommonView.vue'
import BottomMenuButton from '../components/organisms/BottomMenuButton.vue'
import CommonTabMenu from '../components/organisms/CommonTabMenu.vue'
import SectionCareer from '../components/organisms/SectionCareer.vue'
import SectionMailOrder from '../components/organisms/SectionMailOrder.vue'
import LaboratoryCommand from '../components/organisms/LaboratoryCommand.vue'

@Component({
  components: {
    'common-tab-menu': CommonTabMenu
  }
})
export default class TopSection extends Vue {
  private animationViewFlag = false;
  private currentPage = 1;
  private maxPage = 4;
  private minPage = 1;
  private isActiveMenuItem = 'order';
  private alertData = {
    displayMessage: '',
    displayFlag: false
  }

  private created (): void {
    this.init()
  }

  private init (): void {
    this.visitAnimation()
  }

  private visitAnimation (): void {
    this.animationViewFlag = true
    setInterval(() => {
      this.animationViewFlag = false
    }, 1000)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private changePageView (): object {
    const widthValue = (this.currentPage - 2) * -100 + '%'
    const afterWidthValue = (this.currentPage - 1) * -100 + '%'
    return { '--change-slide-before': widthValue, '--change-slide-after': afterWidthValue }
  }

  private prev (): void {
    this.currentPage === 1 ? this.currentPage = this.maxPage : this.currentPage--
  }

  private next (): void {
    this.currentPage === 4 ? this.currentPage = this.minPage : this.currentPage++
  }

  private onSelectButton (selectCurrentPage: number): void {
    this.currentPage = selectCurrentPage
  }

  private isMessage (displayMessage: string): void {
    this.alertData.displayMessage = displayMessage
    this.alertData.displayFlag = true
  }

  private KeyDownFunc (): void {
    console.log('Enter')
  }

  @Watch('currentPage')
  private isActiveMenu (): void {
    switch (this.currentPage) {
      case 1:
        this.isActiveMenuItem = 'order'
        break
      case 2:
        this.isActiveMenuItem = 'portfolio'
        break
      case 3:
        this.isActiveMenuItem = 'career'
        break
      case 4:
        this.isActiveMenuItem = 'mailOrder'
        break
      default:
        break
    }
  }
}
