export interface RequestOrderMail {
  name: string,
  email: string,
  category: string,
  desc: string,
  price: string
}

export const initRequestOrderMail: RequestOrderMail = {
  name: '',
  email: '',
  category: '',
  desc: '',
  price: ''
}
