
import { Component, Vue } from 'vue-property-decorator'
// import AboutDescription from './AboutDescription.vue';

@Component({
  components: {
    // 'about-description': AboutDescription,
  }
})
export default class SectionMailOrder extends Vue {
  // private firstTextEntered = 'くありすの情報発信局へ、ようこそ！\nイラストレーター兼Webエンジニアのくありすと申します。';
  private getViewHeight = 0;
  private created (): void {
    this.init()
  }

  private init (): void {
    this.getViewHeight = window.innerHeight
  }

  private onClick (): void {
    window.open('https://kuarisu.thebase.in/', '_blank')
  }
}
