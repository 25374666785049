
import { Watch, Component, Prop, Vue } from 'vue-property-decorator'
import { OrderRequest } from '@/service/OrderRequest'
import { initRequestOrderMail } from '@/data/interface/requestOrderMail'

@Component({
  components: {}
})
export default class ModalView extends Vue {
  @Prop({ default: false })
  private isActiveFlag!: boolean;

  private orderRequest = new OrderRequest();
  private select = { itemName: 'ここで依頼する', itemKey: 'here' }
  private contentSelect = [
    { itemName: 'ここで依頼する', itemKey: 'here' },
    { itemName: 'Skeb', itemKey: 'skeb' },
    { itemName: 'SKIMA', itemKey: 'skima' },
    { itemName: 'ココナラ', itemKey: 'coconala' }
  ];

  private categorySelect = [
    { itemName: 'イラスト' },
    { itemName: 'Webサイト' }
  ];

  private enteringData = initRequestOrderMail;
  private sendFlag = true;
  private errorFlag = {
    name: false,
    email: false,
    category: false,
    desc: false,
    price: false
  };

  private message = '※入力されると、メール送信できます';
  private displayMessageData = '';

  private created (): void {
    this.init()
  }

  private init (): void {
    this.enteringData.name = ''
    this.enteringData.email = ''
    this.enteringData.category = ''
    this.enteringData.desc = ''
    this.enteringData.price = ''
  }

  private close (): void {
    this.$emit('isActiveFlag', this.isActiveFlag)
  }

  private onClick (onClickType: string): void {
    switch (onClickType) {
      case 'here':
        this.orderRequest.orderMailRequest(this.enteringData)
        console.log(this.orderRequest.responseMessageData())
        this.$emit('isMessage', this.displayMessageData)
        this.init()
        this.close()
        break
      case 'skeb':
        window.open('https://skeb.jp/@Kuarisu/order', '_blank')
        break
      case 'skima-first':
        window.open('http://skima.jp/?utm_source=invite_cp&utm_medium=share&utm_campaign=invite_cp&utm_content=url&sk_code=inviteurl&act=inviteurl&argument=tgGueQWU&dmai=invite_url&promotion_code=ms1HvVXizfjc', '_blank')
        break
      case 'skima':
        window.open('https://skima.jp/profile?id=41566', '_blank')
        break
      case 'coconala-first':
        window.open('https://coconala.com/invate/PR9C8B', '_blank')
        break
      case 'coconala':
        window.open('https://coconala.com/users/1227047', '_blank')
        break
      default:
        break
    }
  }

  @Watch('enteringData.name')
  private onChangeName (): void {
    this.enteringData.name !== '' ? this.errorFlag.name = true : this.errorFlag.name = false
    this.errorCheck()
  }

  @Watch('enteringData.email')
  private onChangeEmail (): void {
    this.enteringData.email !== '' ? this.errorFlag.email = true : this.errorFlag.email = false
    this.errorCheck()
  }

  @Watch('enteringData.category')
  private onChangeCategory (): void {
    this.enteringData.category !== '' ? this.errorFlag.category = true : this.errorFlag.category = false
    this.errorCheck()
  }

  @Watch('enteringData.desc')
  private onChangeDesc (): void {
    this.enteringData.desc !== '' ? this.errorFlag.desc = true : this.errorFlag.desc = false
    this.errorCheck()
  }

  @Watch('enteringData.price')
  private onChangePrice (): void {
    this.enteringData.price !== '' ? this.errorFlag.price = true : this.errorFlag.price = false
    this.errorCheck()
  }

  private errorCheck (): void {
    if (this.errorFlag.name && this.errorFlag.email && this.errorFlag.category && this.errorFlag.desc && this.errorFlag.price) {
      this.sendFlag = false
      this.message = ''
    } else {
      this.message = '※全て入力されるとメール送信できます'
      this.sendFlag = true
    }
  }
}
