
import { Component, Vue } from 'vue-property-decorator'
import { GetPortfolioIllustrationList } from '@/service/GetPortfolioIllustrationList'
import { initRequestGetPortfolioIllustrationList } from '@/data/interface/requestGetPortfolioCDIllustList'
import axios from 'axios'

@Component({
  components: {
    // 'about-description': AboutDescription,
  }
})
export default class SectionPortfolio extends Vue {
  // private firstTextEntered = 'くありすの情報発信局へ、ようこそ！\nイラストレーター兼Webエンジニアのくありすと申します。';
  private responseData = initRequestGetPortfolioIllustrationList;
  private responseObject = {};
  getPortfolioIllustrationList = new GetPortfolioIllustrationList();
  private created () : void {
    this.init()
  }

  private init () : void {
    this.responseObject = this.setResponseData()
  }

  private orderByDesc (orderId: string, itemsLength: number):number {
    return itemsLength - Number(orderId)
  }

  private async setResponseData (): Promise<void> {
    await axios.get(
      'https://script.google.com/macros/s/AKfycbzcAnjodJ8lYYeImhq0QgpePKABNSR_Pd9JfFwQ3MaR5sjOTks/exec'
    ).then(res => {
      this.responseObject = res.data
    })
  }
}
