export interface RequestGetPortfolioCDIllustItems {
  cdJacketId: string,
  cdJacketName: string,
  imagePath: string
}
export interface RequestGetPortfolioCDIllustList {
  portfolioCDIllustList: [RequestGetPortfolioCDIllustItems];
}
export const initRequestGetPortfolioCDIllustItems: RequestGetPortfolioCDIllustItems = {
  cdJacketId: '',
  cdJacketName: '',
  imagePath: ''
}
export const initRequestGetPortfolioIllustrationList: RequestGetPortfolioCDIllustList = {
  portfolioCDIllustList: [initRequestGetPortfolioCDIllustItems]
}
