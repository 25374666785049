import axios from 'axios'
import { RequestOrderMail } from '@/data/interface/requestOrderMail'
import CommonView from '../components/templates/CommonView.vue'

export class OrderRequest {
  private orderMailUrl = 'https://utrkxdbyd8.execute-api.ap-northeast-1.amazonaws.com/dev/mailorder';
  public responseMessage = '';
  private commonView = new CommonView();

  public async orderMailRequest (data: RequestOrderMail): Promise<void> {
    const params = new URLSearchParams()
    params.append('name', data.name)
    params.append('email', data.email)
    params.append('category', data.category)
    params.append('desc', data.desc)
    params.append('price', data.price)
    axios.post(this.orderMailUrl + '?' + params, params)
      .then(response => {
        this.responseMessage = response.data.result
      })
      .catch(() => {
        this.responseMessage = 'エラーです'
      })
  }

  public responseMessageData (): string {
    return this.responseMessage
  }
}
