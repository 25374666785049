
import { Component, Vue } from 'vue-property-decorator'
import FirstView from './FirstView.vue'
import SectionPortfolio from './SectionPortfolio.vue'
import SectionMailOrder from './SectionMailOrder.vue'
import CommonProvide from './CommonProvide.vue'
import ModalView from '../molecules/ModalView.vue'
@Component({
  components: {
    'first-view': FirstView,
    'section-portfolio': SectionPortfolio,
    'common-provide': CommonProvide,
    'modal-view': ModalView,
    'section-mail-order': SectionMailOrder
  }
})
export default class CommonTabMenu extends Vue {
  private currentItem = 'tab-Web';
  private items = ['Top', 'portfolio', 'Videos', 'Store'];
  private offset=true;
  private tab = null;
  private text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';
  private isActiveFlag = false;
  private displayMessageData = '';
  private onClick (): void {
    this.isActiveFlag = true
  }

  private displayMessage (): void {
    this.$emit('isMessage', this.displayMessageData)
  }

  private close (): void {
    this.isActiveFlag = false
  }
}
