import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'

Vue.use(VueRouter)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router: router,
  render: h => h(App)
})
// new Vue({ vuetify, render: h => h(App) }).$mount('#app')
