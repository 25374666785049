/* eslint-disable new-cap */
import axios from 'axios'
// import { RequestGetPortfolioCDIllustList } from '@/data/interface/requestGetPortfolioCDIllustList'
import CommonView from '../components/templates/CommonView.vue'
import { commonHelper } from './common/commonHelper'

export class GetPortfolioIllustrationList {
  private CDIllustListUrl = 'https://script.google.com/macros/s/AKfycbzcAnjodJ8lYYeImhq0QgpePKABNSR_Pd9JfFwQ3MaR5sjOTks/exec';
  public responseData = {};
  private commonView = new CommonView();
  private CommonHelper = new commonHelper();

  private async getPortfolioIllustrationList (): Promise<void> {
    axios.get(
      this.CDIllustListUrl
    ).then(res => {
      // インターフェースと付け合わせる関数を作ろうかと
      // console.log('res:' + JSON.stringify(res.data[1].id))
      this.responseData = res.data
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getCdJacketImageList () {
    return this.getPortfolioIllustrationList()
  }
}
