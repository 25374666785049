
import { Component, Vue } from 'vue-property-decorator'
import AboutDescription from './AboutDescription.vue'
import ModalView from '../molecules/ModalView.vue'

@Component({
  components: {
    'about-description': AboutDescription,
    'modal-view': ModalView
  }
})
export default class FirstView extends Vue {
  private firstTextEntered = 'イラストレーター兼Webエンジニアのホームページ';
  private isActiveFlag = false;
  private displayMessageData = '';

  private onClick (): void {
    this.isActiveFlag = true
  }

  private displayMessage (): void {
    this.$emit('isMessage', this.displayMessageData)
  }

  private close (): void {
    this.isActiveFlag = false
  }
}
