
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AboutDescription extends Vue {
  @Prop({ default: '' })
  private textEntered!: string;

  @Prop({ default: '0px' })
  private viewHeight!: string;

  // data
  private text = '';
  private timer = 0;

  private created (): void {
    this.init()
  }

  private init (): void {
    this.printer()
  }

  private printer (): void {
    this.stoper()
    let i = -1
    this.timer = setInterval(() => {
      i++
      const x = i % this.textEntered.length
      if (i / this.textEntered.length < 1) this.text += this.textEntered[x]
    }, 100 - (Math.random() * 50))
  }

  private stoper (): void {
    clearInterval(this.timer)
    this.text = ''
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private setViewHeight (): object {
    return { height: this.viewHeight }
  }
}
