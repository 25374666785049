import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"second-section"},[[_c(VTabsItems,{model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}},_vm._l((_vm.items.concat(_vm.more)),function(item){return _c(VTabItem,{key:item,attrs:{"value":'tab-' + item}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"pa-0"},[(item==='Top')?_c('div',[_c('first-view',{staticClass:"ma-0 pa-0"})],1):(item==='portfolio')?_c('div',[_c('section-portfolio')],1):(item==='Store')?_c('div',[_c('section-mail-order')],1):_c('div',[_c('common-provide')],1)])],1)],1)}),1),_c(VTabs,{staticClass:"menu-tab",attrs:{"fixed-tabs":""},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}},[_vm._l((_vm.items),function(item){return _c(VTab,{key:item,attrs:{"href":'#tab-' + item}},[_vm._v(" "+_vm._s(item)+" ")])}),_c(VCol,{staticClass:"py-2 pr-5",attrs:{"cols":"2"}},[_c(VBtn,{staticClass:"white--text",attrs:{"color":"grey darken-1","block":"","depressed":""},on:{"click":function($event){return _vm.onClick()}}},[_vm._v(" 依頼する ")])],1)],2)],(_vm.isActiveFlag)?_c('modal-view',{attrs:{"isActiveFlag":_vm.isActiveFlag},on:{"isActiveFlag":function($event){return _vm.close()},"displayMessage":function($event){return _vm.displayMessage()}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }